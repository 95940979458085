import { IconButton, Typography, useTheme, Tooltip, Button } from '@mui/material';
import { styled } from '@mui/material';
import EditIcon from 'assets/icons/edit.svg';
import { Box } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import FeedIcon from '@mui/icons-material/Feed';
import CodeIcon from '@mui/icons-material/IntegrationInstructions';

// import GasStationIcon from 'assets/icons/gasStation.svg';
import DeployIcon from 'assets/icons/deploy.svg';
import { useEffect, useState } from 'react';
import { getContract } from 'store/services/contracts';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'common/Loader';
import CodeView from 'components/smartContract/codeView';
import { useSelector, useDispatch } from 'react-redux';
import { setContract, setSelectedContract, updateContractStatus } from 'store/reducers/contract'
import { compileContract } from 'store/services/contracts';
import DetailsView from 'components/smartContract/detailsView';

const Container = styled('div')(() => ({
    display: 'flex',
    gap: '23px',
    padding: `10px 0 0 30px}`,
    width: '100%',
}));
const StepperChild = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 25px',
    backgroundColor: '#121337',
    height: '39px',
    clipPath: 'polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%)',
    marginRight: '-8px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: 18,
    transform: 'scale(0.85, 0.78)',
    pointerEvents: 'all !important',
    padding: '0px 7px',
    color: '#fff',
    minHeight: '55px',
    minWidth: '135px',
    borderRadius: '8px',
    border: '1px solid #313654',
    backgroundColor: '#121337',
    font: theme.typography.ubuntuMedium,
    margin: '0 auto',
    marginRight: '-10px',
    textTransform: 'none'
}));

const TabText = styled(Typography)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
});

// const CompilerErrorMsg = styled(Box)(({ }) => ({
//     textAlign: 'right',
//     flexGrow: 1,
//     cursor: 'pointer',
//     padding: '5px 0',
//     color: '#de1313',
//     '&:hover': {
//         fontWeight: 600,
//         textDecoration: 'underline',
//     },
// }));

export const ContractTitle = () => {
    const theme = useTheme();
    const contractState = useSelector((state) => state.contract?.contractState)

    return (<Box sx={{ display: 'flex', width: 'inherit', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
                sx={{ font: 'normal normal bold 28px/32px Ubuntu-Bold', color: theme.palette.primary.white }}>
                {contractState?.name || 'N/A'}
            </Typography>
            <IconButton aria-label="edit" onClick={() => { }}>
                <img src={EditIcon} alt="edit icon" />
            </IconButton>
            <Typography
                sx={{
                    font: 'normal normal normal 14px/16px Ubuntu-Regular',
                    color: '#A9A9A9',
                    textTransform: 'capitalize',
                    opacity: 1,
                }}>
                {contractState.status === 'draft'
                    ? `In draft`
                    : `In ${contractState?.status}`}
            </Typography>
        </Box>
    </Box>)
}
export const StepperDetails = ({ active = 0 }) => {
    const stepper = ['Design', 'Validation', 'Security', 'Deployment'];

    return (<Box sx={{ display: 'flex', paddingTop: '4px' }}>
        {stepper.map((elem, index) => (
            <Tooltip title={index === 1 || index === 2 ? 'Coming Soon...' : ''} arrow key={index}>
                <StepperChild key={index}>
                    <Typography
                        sx={{
                            fontSize: 12,
                            backgroundColor: index === active ? 'white' : '#7F7F91',
                            color: 'black',
                            width: '15px',
                            height: '15px',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        {index + 1}
                    </Typography>
                    <Typography
                        sx={{
                            font: 'normal normal normal 14px/11px Ubuntu-Regular',
                            paddingLeft: '6px',
                            color: index === active ? 'primary.white' : '#777777',
                        }}>
                        {elem}
                    </Typography>
                </StepperChild>
            </Tooltip>
        ))}
    </Box>)
}
const SmartContract = () => {
    const active = 0;
    const [loadingState, setLoading] = useState(true);
    const [currentView, handleSwitchView] = useState('code')
    const theme = useTheme();
    const {id: cID} = useParams();
    const router = useNavigate()
    const dispatch = useDispatch();
    const contractState = useSelector((state) => state.contract.contractState)
    let pollingInterval;

    const getContractDetails = async () => {
        try {
            setLoading(true)
            const contract = await getContract(cID);
            dispatch(setContract(contract));

            // set main contract as default selected file
            dispatch(setSelectedContract(contract));

        }
        catch (err) {
            console.log(err)
            toast.error(err?.message, {
                style: { top: '3.5em' },
            });
        }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        if(!cID) return;
        getContractDetails();
        return () => {
            dispatch(setContract({}));
        }
    }, [cID]);


    useEffect(() => {
        // set interval if status is inprogress
        if (contractState?.status === 'inprogress') {
            _getContractInterval()
        }
        return () => {
            clearInterval(pollingInterval);
        }
    }, [contractState?.status])

    const handleDeploy = () => {
        router(`/deployment/${contractState?._id}`);
    }

    const _getContractInterval = () => {
        pollingInterval = setInterval(async () => {
            const rs = await getContract(cID)
            if (rs?.status === 'draft' || rs?.status === 'readyToDeploy') {
                dispatch(setContract(rs));
                clearInterval(pollingInterval);
                toast.success(rs?.status === 'readyToDeploy' ? 'Your contract is ready to deploy!' : 'Please comiple your contract again!', {
                    style: { top: '3.5em' },
                });
            }
        }, 5000)
    }

    const compile = async () => {
        try {
            const { _id, sourceCode, externalFiles } = contractState
            const tomlFile = externalFiles.find(file => file.fileName?.toLowerCase() === 'cargo.toml')
            const { message } = await compileContract({ contractId: _id, contractSourceCode: sourceCode, tomlSourceCode: tomlFile.sourceCode });
            toast.success(message)
            dispatch(updateContractStatus('inprogress'));
        }
        catch (err) {
            console.log(err)
            toast.error(err?.message, {
                style: { top: '3.5em' },
            });
            // setLoading(false);
            clearInterval(pollingInterval);
        }

    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <ContractTitle />
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <StepperDetails active={active} />
                        {contractState?.status === 'deployed' ?
                            <Box>
                                <StyledButton onClick={() => handleSwitchView('code')}
                                    sx={{ backgroundColor: (currentView === 'code' ? '#cb0268' : 'inherit') }}>
                                    <TabText>
                                        <CodeIcon fill={'rgba(255, 255, 255, 0.3)'} />
                                        <span className="responsive" style={{ display: 'block', marginLeft: 5, font: `${theme.typography.ubuntuMedium}`, fontSize: '17px' }}>
                                            Code
                                        </span>
                                    </TabText>
                                </StyledButton>
                                <StyledButton onClick={() => handleSwitchView('details')}
                                    sx={{ backgroundColor: (currentView === 'details' ? '#cb0268' : 'inherit') }}>
                                    <TabText>
                                        <FeedIcon fill={'rgba(255, 255, 255, 0.3)'} />
                                        <span className="responsive" style={{ display: 'block', marginLeft: 5, font: `${theme.typography.ubuntuMedium}`, fontSize: '17px' }}>
                                            Details
                                        </span>
                                    </TabText>
                                </StyledButton>
                            </Box>
                            :
                            <Box>
                                <StyledButton onClick={compile}>
                                    <TabText>
                                        <BoltIcon fill={'rgba(255, 255, 255, 0.3)'} />
                                        <span className="responsive" style={{ display: 'block', marginLeft: 5, font: `${theme.typography.ubuntuMedium}`, fontSize: '17px' }}>
                                            Compile
                                        </span>
                                    </TabText>

                                </StyledButton>

                                <Tooltip placement="top" arrow title={contractState.status?.toLowerCase() === 'readytodeploy' ? '' :
                                    contractState.status?.toLowerCase() === 'inprogress' ? 'Compilation is in progress' : 'Please compile your contract'}
                                >
                                    <StyledButton
                                        sx={{ backgroundColor: '#518574' }}
                                        onClick={handleDeploy}
                                        disabled={
                                            contractState.status?.toLowerCase() !== 'readytodeploy'
                                        }
                                    >
                                        <TabText>
                                            <img style={{ opacity: contractState?.status?.toLowerCase() !== 'readytodeploy' ? 0.5 : 1 }}
                                                src={DeployIcon} alt="deploy" fill={'rgba(255, 255, 255, 0.3)'} />
                                            <span className="responsive" style={{ display: 'block', marginLeft: 5, font: `${theme.typography.ubuntuMedium}`, fontSize: '17px' }}>
                                                Deploy
                                            </span>
                                        </TabText>
                                    </StyledButton>
                                </Tooltip>
                            </Box>
                        }
                        {/* {isError && !contractState.isEditing && (
                            <CompilerErrorMsg onClick={routeToEditor}>Please click here to fix compiler errors</CompilerErrorMsg>
                        )} */}
                    </Box>
                    <Box mt={'20px'}>

                        {loadingState ?
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '70vh',
                                }}>
                                <Loader isScreenLoading={false} />
                            </div> :
                            <>
                                {currentView === 'code' ? <CodeView /> : <DetailsView />}
                            </>

                        }
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
export default SmartContract;