import Explorer from "components/dashboard/Explorer";
import { Box, IconButton, styled } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CodeEditor from "common/Editor";
import { useSelector } from 'react-redux';
import Loader from "common/Loader";
import OutputSection from "./outputSection";
import { useState } from "react";

const FileNameContainer = styled('div')(() => ({
    backgroundColor: '#1e1e1e',
    display: 'flex',
    justifyContent: 'space-between'
}));

const FileName = styled('span')(() => ({
    background: '#518574',
    padding: '1px 25px 5px 10px',
    borderBottomRightRadius: '30px',
    height: '27px'
}));


const CodeView = () => {

    const { selectedConFile, contractState } = useSelector((state) => state?.contract)
    const [outputState, setOutputState] = useState(true)
    const [isDrawerOpen, setDrawerOpen] = useState(true);

    const handleDrawerOpen = () => {
        setDrawerOpen(prevState => !prevState);
    };
  
    const LoaderTextComponent = (
        <>
            <span>Your contract is compiling!</span>
            <span>It may take upto few seconds or a minute...</span>
        </>
    )

    const handleStateChange = () => {
        setOutputState(prevState => !prevState)
    }

    const calcHeight = () => {
        if (contractState?.error && contractState?.error?.trim() !== '') {
            if (!outputState)
                return `calc(100vh - 260px)`
            if (outputState)
                return `calc(100vh - 430px)`
        }
        else return `75vh`
    }
    return <>
        {contractState?.error && contractState?.error?.trim() !== '' && <OutputSection
            expanded={outputState}
            onChange={handleStateChange}
            details={contractState?.error} />}
        <Box display={'flex'} >
            <Explorer open={isDrawerOpen} handleDrawerOpen={handleDrawerOpen} />
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                <FileNameContainer>
                    <FileName>{selectedConFile?._id ? selectedConFile.fileName : selectedConFile.fileName + '.sol'}</FileName>
                    <CopyToClipboard
                        text={selectedConFile?.sourceCode}>
                        <IconButton disableRipple sx={{ marginRight: '10px' }}>
                            <ContentCopyIcon />
                        </IconButton>
                    </CopyToClipboard>
                </FileNameContainer>
                <CodeEditor height={calcHeight()} isDrawerOpen={isDrawerOpen} />

                {contractState.status === 'inprogress' &&
                    <Loader sx={{ zIndex: 1300 }} isScreenLoading={true} showText={true} children={LoaderTextComponent} />}


            </Box>
        </Box>
    </>
}

export default CodeView;