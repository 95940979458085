import React from 'react';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { truncateAddress } from 'utils/wallet';
import subWallet from "../../assets/images/wallet/subWallet.png"

const ConnectWallet = ({ connectWallet, disconnectWallet, wallet }) => {

  const theme = useTheme();
  const { selectedAccount } = useSelector((state) => state.wallet);

  return (
    <Box sx={{ padding: '0.25rem 0.2rem', borderRadius: '5px' }}>
      {wallet?.accounts?.length ? (
        <Tooltip title="Click here to disconnect">
          <Button
            onClick={disconnectWallet}
            variant="text"
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ marginRight: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={subWallet} alt="me" height="30px" width="30px" />
            </Box>
            {/* <Typography variant="body2" sx={{ marginRight: '0.5rem', color: balance == 0 ? 'red' : 'green' }}>
              {balance}
              {getNetworkName(chainId)[1]}
            </Typography> */}
            <Typography sx={{ cursor: 'pointer', marginRight: '0.5rem', color: '#efefef' }} variant="body2">
              {truncateAddress(selectedAccount)}
            </Typography>
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={connectWallet}
          sx={{
            height: '45px', width: '160px', color: 'white', backgroundColor: '#589679',
            fontWeight: 'bold', textTransform: 'capitalize', padding: '28px 0'
          }}
          variant="contained"
          startIcon={<AccountBalanceWalletIcon />}
          disabled={false}
          size="large">
          Connect Wallet
        </Button>


      )

      }
    </Box>
  );
};

export default ConnectWallet;
