import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    contractState: {},
    selectedConFile: {}
}

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setSelectedContract: (state, { payload }) => {
            state.selectedConFile = payload
        },
        setContract: (state, { payload }) => {
            state.contractState = payload
        },
        updateContractStatus: (state, { payload }) => {

            console.log("dafada", payload)
            state.contractState.status = payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setSelectedContract, setContract, updateContractStatus } = contractSlice.actions

export default contractSlice.reducer