import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { CodePromise } from '@polkadot/api-contract';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { web3Enable, web3FromSource } from '@polkadot/extension-dapp';
import { BN } from '@polkadot/util';
import _ from 'lodash';
import { Button, Grid, Container, Box, Typography, styled } from '@mui/material';
import BlockchainTile from './blockChainTile';
import DeployIcon from 'assets/icons/deploy.svg';
import { supportedChains } from 'config/networkChain'
import { ContractTitle, StepperDetails } from 'pages/smartContract';
import { updateContract } from 'store/services/contracts';
import { setContract, setSelectedContract } from 'store/reducers/contract';
import Loader from 'common/Loader';
import { useConnectWallet } from '@subwallet-connect/react';
import { setSelectedAccount } from 'store/reducers/wallet';
import Select from 'common/Select/Select';
import { truncateAddress } from 'utils/wallet';
import MenuItem from '@mui/material/MenuItem';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { getContract } from 'store/services/contracts';

const BalanceCard = styled(Box)({
    padding: '1rem 1.5rem',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});

const StyledContainer = styled(Container)({
    height: 'inherit',
    paddingRight: 0,
});

const Deployment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id: cID} = useParams();
    const [{ wallet }, connect, disconnect, setPrimaryWallet] = useConnectWallet()
    const [supportedChainsCopy, setSupportedChainsCopy] = useState(supportedChains);
    const [selectedNetwork, setSelectedNetwork] = useState(supportedChains[0]);
    const { selectedAccount, } = useSelector((state) => state.wallet);
    const { contractState } = useSelector((state) => state?.contract)
    const [loading, setLoading] = useState(false);
    const children = wallet?.accounts?.map((account, ind) => (<MenuItem key={ind} value={account?.address}>{truncateAddress(account.address)}</MenuItem>)) || []
    if (wallet?.accounts?.length) children.push(<MenuItem key={wallet?.accounts?.length} value={'disconnectwallet'}>{'Disconnect Wallet'}</MenuItem>)

    useEffect(() => {
        if (!cID) return;
        getContractDetails();
    }, [cID]);

    useEffect(() => {
        if (!wallet) return;
        const _selectedAccount = selectedAccount ? selectedAccount : wallet?.accounts[0]?.address
        dispatch(setSelectedAccount(_selectedAccount));

    }, [wallet]);

    useEffect(() => {
        try {
            if (!selectedAccount) return;
            _getAccountBalance();
        } catch (error) {
            console.log("================>Error while getting balance", error.message)
        }
    }, [selectedAccount]);

    useEffect(() => {
        if(!supportedChainsCopy?.length) return;
        setSelectedNetwork(supportedChainsCopy.find(chain => chain?.chain_id === selectedNetwork?.chain_id))
    }, [supportedChainsCopy]);

    const getContractDetails = async () => {
        try {
            setLoading(true)
            const contract = await getContract(cID);
            dispatch(setContract(contract));

            // set main contract as default selected file
            dispatch(setSelectedContract(contract));

        }
        catch (err) {
            console.log(err)
            toast.error(err?.message, {
                style: { top: '3.5em' },
            });
        }
        finally {
            setLoading(false)
        }
    }

    const _getAccountBalance = async () => {
        const updatedChainsCopy = []
        for (const chain of supportedChainsCopy) {
            updatedChainsCopy.push({ ...chain, balance: await getAccountBalance(chain.rpc_url)})
        }
        setSupportedChainsCopy(updatedChainsCopy);
    }

    const getAccountBalance = async (rpcUrl) => {
        const wsProvider = new WsProvider(rpcUrl);
        const api = await ApiPromise.create({ provider: wsProvider });
        const { data: { free: balance, reserved } } = await api.query.system.account(selectedAccount);
        const balanceFormatted = balance / Math.pow(10, 18); // Transferable balance
        const reservedFormatted = reserved / Math.pow(10, 18); // Locked balance
        const TotalBalance = (balanceFormatted + reservedFormatted).toFixed(2);
        return parseFloat(TotalBalance);
    }

    const handleAccountSelection = (event) => {
        if (event.target.value === 'disconnectwallet') {
            disconnect(wallet);
            dispatch(setSelectedAccount(''));
            setSupportedChainsCopy(supportedChainsCopy?.map(chain => ({ ...chain, balance: 0.00 })))
            return;
        }
        dispatch(setSelectedAccount(event.target.value));
    };

    const handleAgreeDeploy = async () => {
        try {
            if (!wallet?.accounts?.length) {
                toast.error('Please connect wallet to deploy contract', {
                    style: { top: '3.5em' },
                });
                return;
            } else if (!selectedAccount) {
                toast.error('Please select an account to deploy contract', {
                    style: { top: '3.5em' },
                });
                return;
            } else if (selectedNetwork?.balance <= 0) {
                toast.error('Insufficient token to deploy contract.', {
                    style: { top: '3.5em' },
                });
                return;
            }
            setLoading(true)
            const _clonedContract = _.cloneDeep(contractState);
            const contract = contractState.byteCode;
            const wsProvider = new WsProvider(selectedNetwork.rpc_url);
            const api = await ApiPromise.create({ provider: wsProvider });
            const code = new CodePromise(api, contract);
            const gasLimit = api.registry.createType("WeightV2", {
                refTime: new BN(100000n * 1000000n),
                proofSize: new BN(3300000),
            });
            const storageDepositLimit = null;
            const initValue = 0;
            await web3Enable('polkadot');
            const injector = await web3FromSource('subwallet-js');
            const tx = code.tx.new({ gasLimit, storageDepositLimit }, initValue);
            const getDeployedContractDetails = new Promise((resolve, reject) => {
                tx.signAndSend(selectedAccount, { signer: injector.signer }, ({ contract, status, txHash }) => {
                    if (status.isInBlock || status.isFinalized) {
                        const contractHash = txHash?.toString();
                        const contractAddress = contract?.address?.toString();
                        resolve({ contractHash, contractAddress });
                    }
                }).catch((error) => {
                    reject(error);
                });
            })
            const { contractHash, contractAddress } = await getDeployedContractDetails;
            updateContract(_clonedContract?._id, { contractHash, contractAddress, chain: selectedNetwork.chain_id })
            _clonedContract.hash = contractHash;
            _clonedContract.address = contractAddress;
            _clonedContract.chain = selectedNetwork.chain_id;
            dispatch(setContract(_clonedContract));
            navigate(`/smartcontracts/${_clonedContract?._id}`)
            toast.success('Contract deployed successfully.', {
                style: { top: '3.5em' },
            });

        } catch (error) {
            console.log("================>ERROR", error.message)
            toast.error(error.message, {
                style: { top: '3.5em' },
            });
        } finally {
            setLoading(false)
        }
    };

    return (
        <StyledContainer maxWidth="false">
            {loading && <Loader />}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        padding: '1rem 0 1rem 0.4rem',
                    }}>
                    <ContractTitle />
                    <StepperDetails />
                    <Box mt={'25px'}>
                        <Typography variant="h2">Blockchain</Typography>
                        <Typography>Select the network to deploy the smart contract</Typography>
                    </Box>

                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                    <Box>
                        {!wallet?.accounts?.length ? (
                            <Button
                                onClick={() => connect()}
                                sx={{
                                    height: '45px', width: '160px', color: 'white', backgroundColor: '#589679',
                                    fontWeight: 'bold', textTransform: 'capitalize', padding: '28px 0'
                                }}
                                variant="contained"
                                startIcon={<AccountBalanceWalletIcon />}
                                disabled={false}
                                size="large">
                                Connect Wallet
                            </Button>) :
                            (<Select
                                label={'Connected Account'}
                                value={selectedAccount}
                                onChange={handleAccountSelection}
                                children={children} />)}
                    </Box>
                    <Button
                        id="deployment-complete"
                        sx={{
                            height: '45px', width: '120px', color: 'white', backgroundColor: '#cc2468',
                            fontWeight: 'bold', textTransform: 'capitalize', padding: '28px 0'
                        }}
                        variant="contained"
                        startIcon={<img src={DeployIcon} alt="icon" />}
                        disabled={false}
                        size="large"
                        onClick={handleAgreeDeploy}>
                        Deploy
                    </Button>
                </Box>
            </Box>

            {/* Available Blockchains */}

            {/* <CustomScrollbar autoHeight autoHeightMax={'calc(100vh - 400px)'}> */}
            <Box sx={{ padding: '0rem 1rem 2rem 0.4rem' }} mt={'2rem'}>
                <Grid id="select-blockchain-network" container justifyContent="center" spacing={3}>
                    {supportedChainsCopy.map((chain) => {
                        return (
                            <Grid key={chain.chain_id} item xs={3}>
                                <BlockchainTile chain={chain} isActive={selectedNetwork?.chain_id} changeNetwork={setSelectedNetwork} />
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
            {/* </CustomScrollbar> */}

            {/* Available Balance */}
            <BalanceCard>
                {/* <WalletBalance network={selectedNetwork} balance={balance} symbol={symbol} /> */}

            </BalanceCard>
        </StyledContainer>
    );
};

export default Deployment;