import React from 'react';
import Form from 'common/form/form';
import Loader from 'common/Loader';
import { object, string } from 'yup';
import useUserForm from 'hooks/useUserForm';
import { styled } from '@mui/material';
import { requestPasswordReset } from 'store/services/auth';
import { toast } from 'react-toastify';

const PageContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));


const RequestPasswordResetSchema = object({
  email: string().required('Email is required').email('Email must be valid'),
});
const defaultErrors = {
  email: [],
};
const defaultFields = {
  email: '',
};

const RequestPasswordReset = () => {

  const { errors, userCredentials, loading, handleChange, handleSubmit } = useUserForm({
    schema: RequestPasswordResetSchema,
    defaultErrors,
    defaultFields,
  });
  const fields = [
    {
      type: 'email',
      label: 'Email Address',
      name: 'email',
      id: 'email',
      value: userCredentials.email,
      errors: errors.email[0],
    },
  ];

  const onSubmition = async () => {
    try{
      const response = await requestPasswordReset(userCredentials);
      if(response.status === 200){
        toast.success(response.successMsg);
        return;
      }
      throw new Error('An error occurred');
    }
    catch (err) {
      toast.error(err, {
        style: { top: '3.5em' },
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <PageContainer>
        <Form
          fields={fields}
          handleChange={handleChange}
          handleSubmit={(event) => handleSubmit(event, onSubmition)}
          callingFrom='reqreset'
        />
      </PageContainer>

    </>
  );
};

export default RequestPasswordReset;
