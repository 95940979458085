import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import Form from 'common/form/form';
import Loader from 'common/Loader';
import { object, string, ref } from 'yup';
import useUserForm from 'hooks/useUserForm';
import { toast } from 'react-toastify';
import { styled } from '@mui/material';
import { verifyLink, resetPassword } from 'store/services/auth';

const PageContainer = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const ResetPasswordSchema = object({
    password: string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    confirmPassword: string()
        .required('Confirm password is required')
        .oneOf([ref('password'), null], 'Passwords does not match'),
});
const defaultErrors = {
    password: [],
    confirmPassword: [],
};
const defaultFields = {
    password: '',
    confirmPassword: '',
};

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id')
    const token = searchParams.get('token')

    const { errors, userCredentials, loading, handleChange, handleSubmit, setLoading } = useUserForm({
        schema: ResetPasswordSchema,
        defaultErrors,
        defaultFields,
    });
    const fields = [
        {
            type: 'password',
            label: 'Password',
            name: 'password',
            id: 'password',
            value: userCredentials.password,
            errors: errors.password[0],
        },
        {
            type: 'password',
            label: 'Confirm Password',
            name: 'confirmPassword',
            id: 'confirmpassword',
            value: userCredentials.confirmPassword,
            errors: errors.confirmPassword[0],
        },
    ];

    useEffect(() => {
        const _verifyLink = async () => {
            try {
                setLoading(true)
                await verifyLink({ userId: id });
            } catch (error) {
                toast.error(error.message, {
                    style: { top: '3.5em' },
                });
                navigate("requestPasswordReset");
            } finally {
                setLoading(false);
            }
        };
        _verifyLink();
    }, []);

    const onSubmition = async () => {
        try{
            const payload = {
                userId: id,
                password: userCredentials.password,
                token
            }
            const response = await resetPassword(payload);
            if(response.status === 200){
                toast.success(response.successMsg);
                navigate('/signin')
                return;
              }
              throw new Error('An error occurred');
        }
        catch (err) {
            toast.error(err, {
              style: { top: '3.5em' },
            });
          }

    };

    return (
        <>
            {loading && <Loader />}
            <PageContainer>
                <Form
                    fields={fields}
                    handleChange={handleChange}
                    handleSubmit={(event) => handleSubmit(event, onSubmition)}
                    callingFrom='reset'
                />
            </PageContainer>

        </>
    );
};

export default ResetPassword;
