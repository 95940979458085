import React, { useState } from 'react';
import { styled, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FilesView from './FilesView';
// import FilesView from './FilesView';

const Panel = styled(Box)(() => ({
  width: 250,
  height: '100%',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRight: '1px solid #383838', backgroundColor: '#1e1f1c',

}));

const Container = styled(Box)(() => ({
  display: 'flex',
  '.icon-button': {
    marginBottom: '10px'
  },
  '.icon': {
    color: '#898989'
  }
}));

const StyledList = styled(List)(() => ({
  backgroundColor: '#272823',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  '.code-view': {
    backgroundColor: '#272845',
    borderRight: '1px solid #121337'
  }
}));


const Explorer = ({open, handleDrawerOpen}) => {
  
  return (
    <Container>
      <StyledList>
        <IconButton onClick={handleDrawerOpen} className='icon-button'>
          <FileCopyOutlinedIcon className='icon' />
        </IconButton>
      </StyledList>
      {open && (
        <Panel>
          <FilesView />
        </Panel>
      )}
    </Container>
  );
};

export default Explorer;
