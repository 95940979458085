import React, { useCallback, useRef } from 'react';
import Editor from '@monaco-editor/react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateContract } from 'store/services/contracts';
import { setContract } from 'store/reducers/contract';

function CodeEditor({height, isDrawerOpen}) {

    const { selectedConFile, contractState } = useSelector((state) => state?.contract)
    const dispatch = useDispatch();

    const isDeployed = () => contractState?.status === 'deployed' || false;

    const saveContract = useCallback(
        _.debounce((value) => {

            // Stop call for deployed contracts
            if (isDeployed())
                return null;

            let _clonedContract = _.cloneDeep(contractState);

            if (selectedConFile?._id === contractState?._id) {
                _clonedContract.sourceCode = value;
            }
            else {
                _clonedContract?.externalFiles?.forEach(c => {
                    if (c?._id === selectedConFile?._id) {
                        c.sourceCode = value;
                    }
                })
            }
            updateContract(selectedConFile?._id, { sourceCode: value })

            dispatch(setContract(_clonedContract));
        }, 300), [selectedConFile?._id]);

    const handleEditorChange = (value) => {
        value = value?.trim();
        if (!value)
            return null;
        saveContract(value);
    };

    return <Editor
        id="code-editor"
        loading={''}
        height={height}
        width={`calc(100vw - ${isDrawerOpen ? '438px' : '188px'})`}
        theme="vs-dark"
        className="overflow-hidden"
        options={{ glyphMargin: true, readOnly: isDeployed(), domReadOnly: isDeployed() }}
        value={selectedConFile?.sourceCode}
        onChange={handleEditorChange}
    />;
}

export default CodeEditor;